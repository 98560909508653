import React from 'react';
import './InternEventsList.scss';

import { Checkbox } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { IEvent, IPublishedEvent, ID, EventStatus, GuestStatus } from '@partiful/model';
import { cn } from '@partiful/web-util';
import { NO_OP, EMPTY_ARR } from '@partiful/util';

export interface IInternEventsListProps {
  className?: string;
  events: IPublishedEvent[];
  activeEventId?: ID<'event'>;
  selectedEventIds?: ID<'event'>[];
  onClick?(event: IPublishedEvent): void;
  onSelect?(event: IPublishedEvent, selected: boolean, newSelectedEventIds: ID<'event'>[]): void;
}

function _InternEventsList({
  className,
  events,
  activeEventId,
  selectedEventIds = EMPTY_ARR,
  onClick = NO_OP,
  onSelect = NO_OP,
}: IInternEventsListProps) {
  return (
    <ul className={cn('ptf-intern-events-list', className)}>
      {events.map((event) => (
        <EventItem
          key={event.id}
          event={event}
          checked={selectedEventIds.includes(event.id)}
          onClick={() => onClick(event)}
          active={event.id === activeEventId}
          onCheck={(checked) =>
            checked
              ? onSelect(event, checked, selectedEventIds.concat(event.id))
              : onSelect(
                  event,
                  checked,
                  selectedEventIds.filter((id) => id !== event.id)
                )
          }
        />
      ))}
    </ul>
  );
}
export const InternEventsList = React.memo(_InternEventsList);
export default InternEventsList;

interface IEventItemProps {
  event: IEvent;
  active?: boolean;
  checked?: boolean;
  onCheck?(checked: boolean): void;
  onClick?: React.EventHandler<React.MouseEvent<HTMLLIElement>>;
}

function EventItem({
  event,
  active = false,
  checked = false,
  onClick = NO_OP,
  onCheck = NO_OP,
}: IEventItemProps) {
  const guestCount = _(event.guestStatusCounts).values().sum();
  const respondedCount = _(event.guestStatusCounts)
    .pick(GuestStatus.MAYBE, GuestStatus.GOING, GuestStatus.DECLINED)
    .values()
    .sum();
  return (
    <li key={event.id} className={cn('event-item', { active, checked })} onClick={onClick}>
      <Checkbox checked={checked} onChange={(e) => onCheck(e.target.checked)} />
      <span className="host-name">{event.hostName}</span>
      <span className="canceled">
        {event.status === EventStatus.CANCELED && `[${event.status}]`}
      </span>
      <span className="event-title">{event.title}</span>
      <span className="guest-count">
        {guestCount === 0 ? '(0)' : `(${respondedCount}/${guestCount})`}
      </span>
      <span className="date">{moment(event.startDate).format('ddd M/D h:mma')}</span>
    </li>
  );
}
