import firebase from 'firebase/app';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

let TEMPLATES: { id: string; template: string }[];

export async function getTemplates(): Promise<{ id: string; template: string }[]> {
  if (TEMPLATES != null) {
    return TEMPLATES;
  }
  const result = await firebase.functions().httpsCallable('getTemplates')();
  TEMPLATES = sortBy(
    map(result.data, (template, id) => ({ template, id })),
    'id'
  );
  return TEMPLATES;
}
