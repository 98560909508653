import './InternHostMessagesList.scss';

import React from 'react';
import { IHostMessage, IGuest } from '@partiful/model';
import { Collapse, Descriptions } from 'antd';
import moment from 'moment';

export interface IInternHostMessagesListProps {
  hostMessages: IHostMessage[];
  guests: IGuest[];
}

function _InternHostMessagesList({ hostMessages }: IInternHostMessagesListProps) {
  return (
    <Collapse className="ptf-intern-host-messages-list">
      {hostMessages.map((m) => (
        <Collapse.Panel
          key={m.id}
          header={
            <div className="host-message-header">
              <span>"{m.text}"</span>
              <span>{moment(m.sentAt).calendar()}</span>
            </div>
          }
        >
          <Descriptions size="small" layout="horizontal" column={1}>
            <Descriptions.Item label="ID">{m.id}</Descriptions.Item>
            <Descriptions.Item label="Sent time">
              {moment(m.sentAt).format('l LTS')}
            </Descriptions.Item>
            <Descriptions.Item label="Status">{m.status}</Descriptions.Item>
            <Descriptions.Item label="Target group">{m.to}</Descriptions.Item>
            <Descriptions.Item label="Sent to">{m.guests.length} guests</Descriptions.Item>
          </Descriptions>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}
export const InternHostMessagesList = React.memo(_InternHostMessagesList);
export default InternHostMessagesList;
