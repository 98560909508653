import { getFirstWord } from './string';
import moment from 'moment-timezone';

export const MAX_GUEST_NAMES_SHOWN = 2;
export const WHITESPACE_REGEX = /\s+/g;

export function formatGuestsForInvitation(guests: { name?: string }[], emptyString = '{Guest}') {
  if (guests.length === 0) {
    return emptyString;
  }
  if (guests.length === MAX_GUEST_NAMES_SHOWN + 1) {
    return guests
      .slice(0, MAX_GUEST_NAMES_SHOWN + 1)
      .map((guest) => getFirstWord(guest.name) || '??')
      .join('/');
  }
  return (
    guests
      .slice(0, MAX_GUEST_NAMES_SHOWN)
      .map((guest) => getFirstWord(guest.name) || '??')
      .join('/') + (guests.length > MAX_GUEST_NAMES_SHOWN ? '/…' : '')
  );
}

export function formatDateForInvitation(date: Date | 'TBD', timezone: string) {
  if (date === 'TBD') {
    return '[date & time TBD]';
  }
  return moment(date).tz(timezone).format('dddd, MMMM Do [at] h:mm A z');
}

export function formatDateForEventList(date: Date) {
  return moment(date).format('ddd M/D h:mma');
}

export function getLastInitial(name: string) {
  const parts = name.split(WHITESPACE_REGEX);
  if (parts.length === 0) {
    return '';
  }
  return parts.pop()?.charAt(0)!;
}

export function formatFirstNameLastInitial(name: string) {
  const firstName = getFirstWord(name);
  if (firstName === name.trim()) {
    return firstName;
  }
  return `${firstName} ${getLastInitial(name).toUpperCase()}.`;
}

export function formatDate(date?: Date) {
  if (date == null) {
    return '';
  }
  return moment(date).format('l LTS');
}

const TIMEZONE_REGEX = /^(.+) ([A-Z])[DS]T$/;

// TODO: unit test.
export function formatDateForSms(date: Date, timezone: string): string {
  const formattedDate = moment(date).tz(timezone).format('MMM Do [at] h:mmA z');
  if (TIMEZONE_REGEX.test(formattedDate)) {
    // EDT/EST => ET
    return formattedDate.replace(TIMEZONE_REGEX, '$1 $2T');
  }
  return formattedDate;
}

export function formatTimeForSms(date: Date, timezone: string): string {
  const formattedDate = moment(date).tz(timezone).format('h:mmA z');
  if (TIMEZONE_REGEX.test(formattedDate)) {
    // EDT/EST => ET
    return formattedDate.replace(TIMEZONE_REGEX, '$1 $2T');
  }
  return formattedDate;
}
