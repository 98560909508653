export function quote(s: string): string {
  return `${s.startsWith('“') ? '' : '“'}${s}${s.endsWith('”') ? '' : '”'}`;
}

export function unQuote(s: string): string {
  const startIndex = s.startsWith('“') ? 1 : 0;
  const endIndex = s.endsWith('”') ? s.length - 1 : 0;
  return s.slice(startIndex, endIndex);
}

export function isQuoted(s: string): boolean {
  return s[0] === '“' && s[s.length - 1] === '”';
}

export function getFirstWord(s: string | undefined) {
  return s?.split(' ')[0];
}

export function capitalize(s: string) {
  return s[0].toLocaleUpperCase() + s.slice(1);
}

export function trimWhitespace(s?: string) {
  return s?.trim();
}

const ARTICLE_REGEX = /^(a|the|an) /i;

export function trimArticle(s: string) {
  if (ARTICLE_REGEX.test(s)) {
    return s.replace(ARTICLE_REGEX, '');
  }
}

export const VOWELS = 'aeiou';

export function prependArticle(s?: string, type = 'a' as 'a' | 'the' | 'The' | 'A') {
  if (s == null) {
    return s;
  }
  if (type.toLowerCase() === 'a' && VOWELS.includes(s[0].toLowerCase())) {
    return `${type}n ${s}`;
  }
  return `${type} ${s}`;
}

export function hasArticle(s: string) {
  return ARTICLE_REGEX.test(s);
}
