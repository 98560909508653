import lodashIsEqual from 'lodash/isEqual';
import lodashRange from 'lodash/range';
import lodashMapValues from 'lodash/mapValues';
import lodashOmitBy from 'lodash/omitBy';
import lodashOmit from 'lodash/omit';
import lodashPick from 'lodash/pick';
import lodashFlatMap from 'lodash/flatMap';
import lodashSum from 'lodash/sum';
import lodashValues from 'lodash/values';
import lodashDefer from 'lodash/defer';
import lodashZipObject from 'lodash/zipObject';
import lodashCompact from 'lodash/compact';
import lodashMapKeys from 'lodash/mapKeys';
import lodashStartCase from 'lodash/startCase';
import lodashDebounce from 'lodash/debounce';

// import lodashKeysIn from 'lodash/keysIn';

export const range = lodashRange;
export const deepEqual = lodashIsEqual;
export const mapValues = lodashMapValues;
export const omitBy = lodashOmitBy;
export const omit = lodashOmit;
export const flatMap = lodashFlatMap;
export const sum = lodashSum;
export const values = lodashValues;
export const defer = lodashDefer;
export const pick = lodashPick;
export const zipObject = lodashZipObject;
export const compact = lodashCompact;
export const mapKeys = lodashMapKeys;
export const startCase = lodashStartCase;
// export const keysIn = lodashKeysIn;
export const debounce = lodashDebounce;
