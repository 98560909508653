import './InternGuestList.scss';

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Tooltip,
  Collapse,
  Descriptions,
  Popover,
  Menu,
  notification,
  Dropdown,
} from 'antd';
import { map, omit, startCase } from 'lodash';
import {
  CommentOutlined,
  CopyOutlined,
  BellOutlined,
  BellFilled,
  MailOutlined,
  LoadingOutlined,
  DownOutlined,
} from '@ant-design/icons';

import {
  IGuest,
  GuestStatus,
  GuestMessageTypes,
  IMessage,
  ReceivedMessageTypes,
} from '@partiful/model';
import { cn } from '@partiful/web-util';

import { formatDate, formatPhoneNumber } from 'src/util';
import { getReminders, sendReminder } from 'src/api';
import { updateGuestStatus, getGuestMessages } from 'src/firestore';

export interface IInternGuestListProps {
  className?: string;
  guests: IGuest[];
}

export const GuestActions = React.memo(_GuestActions);
export const GuestDetails = React.memo(_GuestDetails);
function _InternGuestList({ className, guests }: IInternGuestListProps) {
  const [reminders, setReminders] = useState<any[]>([]);
  useEffect(() => {
    let isMounted = true;
    getReminders().then((reminders) => {
      if (isMounted) {
        setReminders(reminders);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <Collapse className={cn('ptf-intern-guest-list', className)}>
      {guests.map((g) => (
        <Collapse.Panel
          key={g.id}
          className="guest-item"
          header={<span>{g.name}</span>}
          showArrow={false}
          extra={<GuestActions guest={g} reminders={reminders} />}
        >
          <GuestDetails guest={g} reminders={reminders} />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}
export const InternGuestList = React.memo(_InternGuestList);
export default InternGuestList;

function _GuestDetails({ guest, reminders }: { guest: IGuest; reminders: any[] }) {
  const [inviteResponses, setInviteResponses] = useState<IMessage[]>([]);
  useEffect(() => {
    getGuestMessages(guest.eventId, guest.id).then((messages) => {
      setInviteResponses(
        messages.filter(
          (m) =>
            m.type !== ReceivedMessageTypes.RSVP &&
            (m.previousType === GuestMessageTypes.INVITE ||
              m.previousType === GuestMessageTypes.RSVP_REMINDER)
        )
      );
    });
  }, [guest.eventId, guest.id]);
  const copyIdToClipboard = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const dummyInput = document.createElement('input');
      dummyInput.value = guest.id;
      document.body.appendChild(dummyInput);
      dummyInput.select();
      document.execCommand('copy');
      document.body.removeChild(dummyInput);
    },
    [guest.id]
  );
  return (
    <Descriptions className="guest-details" size="small" layout="horizontal" column={1}>
      <Descriptions.Item label="ID">
        {guest.id}
        <Tooltip overlay="Copy ID" placement="top">
          <Button size="small" type="link" icon={<CopyOutlined />} onClick={copyIdToClipboard} />
        </Tooltip>
      </Descriptions.Item>
      <Descriptions.Item label="Status">{guest.status}</Descriptions.Item>

      {guest.timezone && <Descriptions.Item label="Time Zone">{guest.timezone}</Descriptions.Item>}
      {guest.inviteDate && (
        <Descriptions.Item label="Invite Time">{formatDate(guest.inviteDate)}</Descriptions.Item>
      )}
      <Descriptions.Item label="Phone">{formatPhoneNumber(guest.phoneNumber)}</Descriptions.Item>
      {guest.rsvpDate && (
        <Descriptions.Item label="RSVP Time">{formatDate(guest.rsvpDate)}</Descriptions.Item>
      )}
      {guest.rsvpOrigin && (
        <Descriptions.Item label="RSVP Origin">{guest.rsvpOrigin}</Descriptions.Item>
      )}
      {guest.inviteShortUrl && (
        <Descriptions.Item label="RSVP Link">{guest.inviteShortUrl}</Descriptions.Item>
      )}
      <Descriptions.Item label="Plus Ones">{guest.plusOneCount}</Descriptions.Item>
      <Descriptions.Item label="Contact ID">{guest.contactId}</Descriptions.Item>
      <Descriptions.Item label="Sent Reminders">
        {guest.sentReminders
          ?.map((id) => reminders.find((r) => r.id === id)?.name || id)
          .join(', ')}
      </Descriptions.Item>
      <Descriptions.Item label="Response Text">
        {inviteResponses.map((m) => (
          <span key={m.id}>"{m.body}"</span>
        ))}
      </Descriptions.Item>
      {(guest.covidQuarantineConfirmed != null || guest.covidTestConfirmed != null) && (
        <Descriptions.Item label="COVID Safety Info">
          Quarantine: {guest.covidQuarantineConfirmed ? 'Yes' : 'No'}
          <br />
          Test: {guest.covidTestConfirmed ? 'Yes' : 'No'}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}

const STATUS_DROPDOWN_TRIGGER = ['click' as const];

function _GuestActions({ guest, reminders }: { guest: IGuest; reminders: any[] }) {
  const [remindersOpen, setRemindersOpen] = useState<boolean>(false);
  const [sendingReminderId, setSendingReminderId] = useState<string>();
  const selectedStatusKeys = useMemo(() => [guest.status], [guest.status]);
  const clickReminder = useCallback(
    ({ key: id }) => {
      console.log('sending reminder', id);
      setSendingReminderId(id);
      sendReminder({ reminderId: id, guestId: guest.id, eventId: guest.eventId }).then(() => {
        console.log('sent reminder!');
        setSendingReminderId(undefined);
        notification.success({ message: 'Reminder sent!' });
      });
    },
    [guest]
  );
  const popoverOpenOrClose = useCallback((open: boolean) => {
    setRemindersOpen(open);
  }, []);
  const changeStatus = useCallback(
    ({ key: status }) => {
      updateGuestStatus(guest.eventId, guest.id, status).then(() => {
        console.log('updated status', status);
      });
    },
    [guest.id, guest.eventId]
  );
  return (
    <div className="actions" onClick={stopPropagation}>
      <Dropdown
        placement="bottomRight"
        trigger={STATUS_DROPDOWN_TRIGGER}
        overlay={
          <Menu
            className="ptf-intern-invite-status-menu"
            onClick={changeStatus}
            selectedKeys={selectedStatusKeys}
          >
            {map(
              omit(GuestStatus, 'UNSENT', 'SENDING', 'SEND_ERROR', 'DELIVERY_ERROR', 'SEEN'),
              (status) => (
                <Menu.Item key={status}>
                  {status === GuestStatus.READY_TO_SEND
                    ? 'Re-send invite'
                    : startCase(status.toLowerCase())}
                </Menu.Item>
              )
            )}
            <Menu.Item className="info-item" key="info" disabled={true}>
              Note: Changing status will send a new text confirmation.
            </Menu.Item>
          </Menu>
        }
      >
        <Button>
          {guest.status === GuestStatus.READY_TO_SEND
            ? 'Re-sending...'
            : startCase(guest.status.toLowerCase())}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Popover
        overlayClassName="ptf-intern-guest-reminders-popover"
        onVisibleChange={popoverOpenOrClose}
        title="Test send reminders"
        content={
          <Menu
            className="ptf-intern-guest-reminders-menu"
            onClick={clickReminder}
            selectable={false}
          >
            {reminders.map((r) => (
              <Menu.Item key={r.id} disabled={sendingReminderId === r.id}>
                {sendingReminderId === r.id ? <LoadingOutlined /> : <MailOutlined />}
                {r.name}
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomRight"
        trigger="click"
      >
        <Button type="link" icon={remindersOpen ? <BellFilled /> : <BellOutlined />} />
      </Popover>
      <Tooltip overlay="View message history" placement="topRight">
        <Button
          type="link"
          icon={<CommentOutlined />}
          href={`/messages/${guest.phoneNumber}`}
          target="_BLANK"
        />
      </Tooltip>
    </div>
  );
}

function stopPropagation(e: React.SyntheticEvent<any>) {
  e.stopPropagation();
}
