import React, { useMemo } from 'react';
import './InternAppHeader.scss';
import { Input, Layout, Menu, AutoComplete } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

export function InternAppHeader() {
  const { pathname } = useLocation();
  const selectedMenuKeys = useMemo(() => [pathname.split('/')[1]], [pathname]);
  function search(input: string) {
    // TODO: Implement search.
    console.log('search', input);
  }
  return (
    <Layout.Header className="ptf-intern-app-header">
      <Menu className="nav-menu" theme="dark" mode="horizontal" selectedKeys={selectedMenuKeys}>
        <Menu.Item key="search">
          <AutoComplete onSearch={search} disabled={true}>
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </AutoComplete>
        </Menu.Item>
        <Menu.Item key="users">
          <Link to="/users">Users</Link>
        </Menu.Item>
        <Menu.Item key="events">
          <Link to="/events">Events</Link>
        </Menu.Item>
        <Menu.Item key="messages">
          <Link to="/messages">Messages</Link>
        </Menu.Item>
        <Menu.Item key="templates">
          <Link to="/templates">Templates</Link>
        </Menu.Item>
      </Menu>
    </Layout.Header>
  );
}
