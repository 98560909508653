import {
  IUser,
  EventStatus,
  IPublishedEvent,
  IMessage,
  ICommunicationTarget,
  GuestStatus,
  ID,
  IGuest,
  isRsvpStatus,
} from '@partiful/model';
import {
  usersCollection,
  eventsCollection,
  getQueryData,
  guestRef,
  messagesCollection,
  getMessageFromDoc,
  guestsCollection,
  getGuestFromDoc,
  getEventFromDoc,
  hostMessageRef,
  userRef,
} from '@partiful/firestore/build/web';
import { root } from '@partiful/firestore';
import firebase from 'firebase/app';

export async function getUsers(limit = 500): Promise<IUser[]> {
  const usersQuery = await usersCollection().where('isAnonymous', '==', false).limit(limit).get();
  return getQueryData(usersQuery);
}

export async function getEvents(limit = 1000): Promise<IPublishedEvent[]> {
  const eventsQuery = await eventsCollection()
    .where('status', 'in', [EventStatus.PUBLISHED, EventStatus.CANCELED])
    .limit(limit)
    .get();
  return getQueryData<IPublishedEvent>(eventsQuery, getEventFromDoc);
}

export async function getEventsForUser(userId: ID<'user'>, limit = 500) {
  const eventsQuery = await eventsCollection()
    .where('owners', 'array-contains', userRef(userId))
    .limit(limit)
    .get();
  return getQueryData<IPublishedEvent>(eventsQuery, getEventFromDoc);
}

export async function getLatestMessages(commId: string, channelId: string, limit = 500) {
  const messagesQuery = await messagesCollection(commId, channelId)
    .orderBy('date', 'desc')
    .limit(limit)
    .get();
  return getQueryData<IMessage>(messagesQuery, getMessageFromDoc);
}

export async function getLastMessage(commId: string, channelId: string) {
  const messagesQuery = await messagesCollection(commId, channelId)
    .where('direction', '==', 'outbound')
    .orderBy('date', 'desc')
    .limit(1)
    .get();
  if (messagesQuery.size === 0) {
    return undefined;
  }
  return getQueryData<IMessage>(messagesQuery)[0];
}

export async function getPhoneNumbers() {
  const numbers = await root().collection('comms').orderBy('name', 'asc').get();
  return getQueryData<ICommunicationTarget>(numbers);
}

export function onMessagesUpdated(
  commId: string,
  channelId: string,
  onUpdated: (messages: IMessage[]) => void,
  onError?: (e: Error) => void
): () => void {
  return messagesCollection(commId, channelId)
    .where('date', '>=', new Date())
    .orderBy('date', 'asc')
    .onSnapshot((snapshot) => {
      const messages = getQueryData(snapshot, getMessageFromDoc);
      if (messages.length > 0) {
        onUpdated(messages);
      }
    }, onError);
}

export function updateGuestStatus(
  eventId: ID<'event'>,
  guestId: ID<'guest'>,
  status: GuestStatus,
  // TODO: Deal with controlling sending confirmations.
  skipConfirmation?: boolean
): Promise<void> {
  const additionalData: { rsvpDate?: Date } = {};
  if (isRsvpStatus(status)) {
    additionalData.rsvpDate = new Date();
  }
  return guestRef(eventId, guestId).update({
    status,
    statusManuallySet: true,
    ...additionalData,
  }) as Promise<void>;
}

export function onGuestsChanged(
  eventId: ID<'event'>,
  callback: (guests: IGuest[]) => void,
  onError?: (error: Error) => void
): () => void {
  return guestsCollection(eventId).onSnapshot((snapshot) => {
    callback(getQueryData(snapshot, getGuestFromDoc));
  }, onError);
}

export async function getResponsesToHostMessage(
  eventId: ID<'event'>,
  hostMessageId: ID<'host_message'>
) {
  const queryResults = await firebase
    .firestore()
    .collectionGroup('messages')
    .where('hostMessage', '==', hostMessageRef(eventId, hostMessageId))
    .where('direction', '==', 'inbound')
    .get();
  return getQueryData(queryResults, getMessageFromDoc);
}

export async function getGuestMessages(eventId: ID<'event'>, guestId: ID<'guest'>) {
  const queryResults = await firebase
    .firestore()
    .collectionGroup('messages')
    .where('guest', '==', guestRef(eventId, guestId))
    .get();
  return getQueryData(queryResults, getMessageFromDoc);
}
