export function isBackspace(e: React.KeyboardEvent<any>) {
  return e.key === 'Delete' || e.key === 'Backspace' || e.keyCode === 8 || e.keyCode === 46;
}

export function isTab(e: React.KeyboardEvent<any>) {
  return e.key === 'Tab' || e.keyCode === 9;
}

export function isEnter(e: React.KeyboardEvent<any>, withShift?: boolean) {
  return (
    e.key === 'Enter' &&
    (withShift == null ||
      (withShift === true && e.shiftKey) ||
      (withShift === false && !e.shiftKey))
  );
}

export function isLeftArrow(e: React.KeyboardEvent<any>) {
  return e.key === 'ArrowLeft';
}

export function isRightArrow(e: React.KeyboardEvent<any>) {
  return e.key === 'ArrowRight';
}

export function isDownArrow(e: React.KeyboardEvent<any>) {
  return e.key === 'ArrowRight';
}
