import React, { useRef, useCallback } from 'react';
import './InternEventsFilters.scss';

import type { FormInstance } from 'antd/lib/form';
import { Checkbox, Radio, DatePicker, Form, Input, Select, TimePicker, Switch, Slider } from 'antd';
import moment from 'moment';

import { ID, IUser, IPublishedEvent, EventStatus } from '@partiful/model';
import { cn } from '@partiful/web-util';
import { NO_OP, EMPTY_ARR, defer } from '@partiful/util';

export enum DateFilterType {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  ALL = 'ALL',
}

export interface IEventsFilterState {
  search?: string;
  statuses?: EventStatus[];
  dateFilter?: DateFilterType;
  createDateRange?: [moment.Moment | undefined, moment.Moment | undefined];
  dateRange?: [moment.Moment | undefined, moment.Moment | undefined];
  timeRange?: [moment.Moment | undefined, moment.Moment | undefined];
  guestCountRange: [number, number]; // 31 = 30+
  selectedUsers?: ID<'user'>[];
  excludeEmployeeEvents: boolean;
}

export interface IInternEventsFiltersProps extends IEventsFilterState {
  formName: string;
  form?: FormInstance;
  className?: string;
  users?: IUser[];
  onChange?(filterState: IEventsFilterState): void;
}

export const EMPTY_DATE_RANGE = [undefined, undefined] as [undefined, undefined];

export const DEFAULT_FILTER_STATE: IEventsFilterState = {
  statuses: [EventStatus.PUBLISHED],
  dateFilter: DateFilterType.UPCOMING,
  dateRange: EMPTY_DATE_RANGE,
  createDateRange: EMPTY_DATE_RANGE,
  timeRange: EMPTY_DATE_RANGE,
  selectedUsers: [],
  guestCountRange: [0, 31],
  search: '',
  excludeEmployeeEvents: true,
};

const DATEPICKER_ALLOW_EMPTY = [true, true] as [boolean, boolean];
const CREATE_DATE_RANGE_PLACHOLDER = ['Min create date', 'Max create Date'] as [string, string];

function getTimeMoment(date: Date) {
  return moment().hours(date.getHours()).minutes(date.getMinutes());
}

export function applyFiltersToEvents(
  events: IPublishedEvent[],
  {
    statuses,
    selectedUsers,
    search,
    dateFilter,
    dateRange: [startDate, endDate],
    timeRange: [startTime, endTime],
    createDateRange: [startCreateDate, endCreateDate],
    excludeEmployeeEvents,
    guestCountRange: [minGuestCount, maxGuestCount],
  }: IEventsFilterState,
  users: IUser[] = EMPTY_ARR
): IPublishedEvent[] {
  return events.filter(
    (e) =>
      statuses?.includes(e.status) &&
      (!selectedUsers?.length || e.owners?.some((o) => selectedUsers.includes(o.id))) &&
      (!search || e.title?.toLowerCase()?.includes(search.toLowerCase())) &&
      e.guestCount >= minGuestCount &&
      (maxGuestCount > 30 || e.guestCount <= maxGuestCount) &&
      (dateFilter === DateFilterType.UPCOMING
        ? moment().isBefore(moment(e.startDate))
        : dateFilter === DateFilterType.PAST
        ? moment().isAfter(moment(e.startDate))
        : (!startDate || moment(e.startDate).isSameOrAfter(startDate, 'day')) &&
          (!endDate || moment(e.startDate).isSameOrBefore(endDate, 'day'))) &&
      (!startTime || getTimeMoment(e.startDate as Date).isSameOrAfter(startTime, 'hour')) &&
      (!endTime || getTimeMoment(e.startDate as Date).isSameOrBefore(endTime, 'hour')) &&
      (!startCreateDate || moment(e.publishedAt).isSameOrAfter(startCreateDate, 'day')) &&
      (!endCreateDate || moment(e.publishedAt).isSameOrBefore(endCreateDate, 'day')) &&
      (!excludeEmployeeEvents ||
        !e.owners.some((o) =>
          users.find((u) => u.id === o.id)?._tags?.some((t) => t === 'employee' || t === 'test')
        ))
  ) as IPublishedEvent[];
}

const GUEST_COUNT_MARKS = {
  0: '0',
  31: '30+',
};

function _InternEventsFilters({
  form,
  formName,
  className,
  onChange = NO_OP,
  users = EMPTY_ARR,
  ...filterState
}: IInternEventsFiltersProps) {
  const newForm = Form.useForm()[0];
  const formRef = useRef<FormInstance>(form || newForm);
  const updateFilter = useCallback(
    (values: Partial<IEventsFilterState>) => {
      let reset = false;
      if (values.dateRange != null) {
        values.dateFilter = DateFilterType.ALL;
        reset = true;
      } else if (values.dateFilter != null && values.dateFilter !== DateFilterType.ALL) {
        values.dateRange = DEFAULT_FILTER_STATE.dateRange;
        reset = true;
      }
      if (values.dateRange === null) {
        values.dateRange = EMPTY_DATE_RANGE;
      }
      if (values.timeRange === null) {
        values.timeRange = EMPTY_DATE_RANGE;
      }
      if (values.createDateRange === null) {
        values.createDateRange = EMPTY_DATE_RANGE;
      }
      onChange({ ...DEFAULT_FILTER_STATE, ...filterState, ...values });
      if (reset) {
        defer(() => {
          formRef.current.resetFields();
        });
      }
    },
    [filterState, onChange]
  );
  return (
    <div className="ptf-intern-events-filters">
      <Form
        name={formName}
        form={formRef.current}
        className={cn('ptf-intern-events-filters', className)}
        layout="horizontal"
        colon={false}
        initialValues={{ ...DEFAULT_FILTER_STATE, ...filterState }}
        onValuesChange={updateFilter}
      >
        <Form.Item name="search">
          <Input placeholder="Search event title" />
        </Form.Item>
        <Form.Item name="statuses">
          <Checkbox.Group>
            <Checkbox value={EventStatus.PUBLISHED}>Published</Checkbox>
            <Checkbox value={EventStatus.CANCELED}>Canceled</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item name="dateFilter">
          <Radio.Group>
            <Radio.Button value={DateFilterType.ALL}>All</Radio.Button>
            <Radio.Button value={DateFilterType.UPCOMING}>Upcoming</Radio.Button>
            <Radio.Button value={DateFilterType.PAST}>Past</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="createDateRange">
          <DatePicker.RangePicker
            allowEmpty={DATEPICKER_ALLOW_EMPTY}
            format="M/DD/YYYY"
            placeholder={CREATE_DATE_RANGE_PLACHOLDER}
          />
        </Form.Item>
        <Form.Item name="dateRange">
          <DatePicker.RangePicker allowEmpty={DATEPICKER_ALLOW_EMPTY} format="M/DD/YYYY" />
        </Form.Item>
        <Form.Item name="timeRange">
          <TimePicker.RangePicker
            showMinute={false}
            showSecond={false}
            format="hA"
            hideDisabledOptions={true}
            allowEmpty={DATEPICKER_ALLOW_EMPTY}
          />
        </Form.Item>
        <Form.Item name="guestCountRange" label="# guests">
          <Slider
            range={true}
            min={0}
            max={31}
            marks={GUEST_COUNT_MARKS}
            tipFormatter={formatGuestCountTooltip}
          />
        </Form.Item>
        <Form.Item name="selectedUsers">
          <Select mode="multiple" placeholder="Filter to specific users">
            {users.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="excludeEmployeeEvents" label="Exclude employees" labelAlign="right">
          <Switch checked={filterState.excludeEmployeeEvents} />
        </Form.Item>
      </Form>
    </div>
  );
}

function formatGuestCountTooltip(val: number | undefined) {
  if (val === 31) {
    return '30+';
  }
  return val || null;
}

export const InternEventsFilters = React.memo(_InternEventsFilters);
export default InternEventsFilters;
