import * as dot from 'dot';
import _ from 'lodash';
import moment from 'moment-timezone';
import { isPast, formatDate } from '@partiful/util';
import { formatDuration, subHours } from 'date-fns';

import {
  IEvent,
  IGuest,
  getRsvpLabel,
  isRsvpStatus,
  getResponseLabelForStatus,
} from '@partiful/model';

import { formatDateForSms, formatTimeForSms, Str } from '../util';

const TEMPLATE_SETTINGS = {
  ...dot.templateSettings,
  varname: 'event, guest',
  strip: false,
};

export function getTemplateFunc(id: string, template: string) {
  const tempf = dot.template(template, TEMPLATE_SETTINGS);
  return (event?: IEvent, guest?: IGuest, additionalEventInfo?: any) => {
    const eventData = event
      ? { ...getEventData(event, id.startsWith('host')), ...additionalEventInfo }
      : {};
    const guestData = guest
      ? {
          name: Str.getFirstWord(guest.name),
          rsvp: isRsvpStatus(guest.status) ? getRsvpLabel(guest.status) : '',
          status: getResponseLabelForStatus(guest.status),
          rsvpLink: guest.inviteShortUrl || 'https://par.tf/notareallink',
        }
      : {};
    const output = tempf(eventData, guestData);
    return output.split('\r');
  };
}

export interface IEventInfo
  extends Pick<
    IEvent,
    | 'title'
    | 'guestCount'
    | 'declinedGuestCount'
    | 'goingGuestCount'
    | 'maybeGuestCount'
    | 'unrespondedGuestCount'
    | 'respondedGuestCount'
    | 'waitlistGuestCount'
    | 'hostName'
    | 'invitationMessage'
    | 'covidSettings'
  > {
  displayStartDate: string;
  displayStartTime: string;
  twoHoursBefore: string;
  shortDisplayDate: string;

  publicLink?: string;
  isToday?: boolean;
  isTmrw?: boolean;
  // Within next 2 hours.
  isSoon?: boolean;
  daysUntilEvent: number;
  includeSentVia?: boolean;
  location?: string;

  isFirstEvent?: boolean;
  link?: string;
  allGuestsResponded?: boolean;
  hostMessage?: string;
  quarantinePeriod?: string;
  testingPeriod?: string;
  guestCountsDiff?: {
    going: number;
    goingNames?: string;
    declined: number;
    declinedNames?: string;
    maybe: number;
    maybeNames?: string;
  };
}

const MAX_SHOWN_TITLE_LENGTH = 30;

export function getEventData(
  event: IEvent & { hostMessage?: string; isFirstEvent?: boolean },
  forHost = false
): IEventInfo {
  const message = event.invitationMessage || event.description;
  const daysUntilEvent = moment
    .duration(
      moment(event.startDate)
        .tz(event.timezone)
        .startOf('day')
        .diff(moment.tz(event.timezone).startOf('day'))
    )
    .asDays();
  let info: IEventInfo = {
    ..._.pick(
      event,
      'hostName',
      'location',
      'guestCount',
      'respondedGuestCount',
      'unrespondedGuestCount',
      'goingGuestCount',
      'declinedGuestCount',
      'maybeGuestCount',
      'waitlistGuestCount'
    ),
    title:
      // TODO: Optimize this so it doesn't just cut off near the end of a word, e.g. avoid "Shreya's 30 Birthday Fiest…"
      event.title.length > 30 ? event.title.slice(0, MAX_SHOWN_TITLE_LENGTH) + '…' : event.title,
    daysUntilEvent,
    isToday: daysUntilEvent === 0,
    isTmrw: daysUntilEvent === 1,
    isSoon: event.startDate !== 'TBD' && isPast(subHours(event.startDate, 2)),
    invitationMessage: event.invitationMessage || event.description?.slice(0, 320),
    includeSentVia: message?.toLowerCase().indexOf('partiful') === -1,
    displayStartDate:
      typeof event.startDate === 'string'
        ? '[date & time TBD]'
        : formatDateForSms(event.startDate, event.timezone),
    displayStartTime:
      typeof event.startDate === 'string'
        ? '[time TBD]'
        : formatTimeForSms(event.startDate, event.timezone),
    shortDisplayDate: event.startDate === 'TBD' ? 'TBD' : formatDate(event.startDate, 'M/dd'),
    twoHoursBefore:
      event.startDate === 'TBD' ? 'TBD' : formatDate(subHours(event.startDate, 2), 'h:mma'),
    hostMessage: event.hostMessage,
    isFirstEvent: event.isFirstEvent,
    covidSettings: event.covidSettings || {},
    testingPeriod:
      event.covidSettings?.testedWithinDays === 7
        ? 'week'
        : event.covidSettings?.testedWithinDays != null
        ? formatDuration({ days: event.covidSettings.testedWithinDays })
        : undefined,
    quarantinePeriod:
      event.covidSettings?.quarantineDays === 7
        ? 'week'
        : event.covidSettings?.quarantineDays != null
        ? // TODO: This only handles whole number weeks
          formatDuration({ weeks: event.covidSettings.quarantineDays / 7 })
        : undefined,
    publicLink:
      event.visibility === 'public' ? `https://events.getpartiful.com/${event.id}` : undefined,
  };
  if (forHost) {
    info = {
      ...info,
      allGuestsResponded: event.unrespondedGuestCount === 0,
      link: event.shortUrl || '',
    };
  }
  return info;
}
