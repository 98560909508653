import hmacSHA1 from 'crypto-js/hmac-sha1';
import base64 from 'crypto-js/enc-base64';
import base64url from 'base64url';

export var convertPhoneNumberToCommId: (phoneNumber: string) => string = (s: string) => s;

export function makePhoneNumberToCommIdConverter(key: string) {
  const converter = (phoneNumber: string) => {
    const hmac = hmacSHA1(phoneNumber, key);
    const b64hash = hmac.toString(base64);
    return 'PH' + base64url.fromBase64(b64hash);
  };
  convertPhoneNumberToCommId = converter;
  return converter;
}

// export function convertPhoneNumberToCommId(phoneNumber: string) {
//   if (!phoneNumber.startsWith('+1')) {
//     return phoneNumber;
//   }
//   if (encryptKey == null) {
//     return phoneNumber;
//     throw new Error('Phone number encrypt key not set');
//   }
//   const hmac = hmacSHA1(phoneNumber, encryptKey);
//   const b64hash = hmac.toString(base64);
//   return 'PH' + base64url.fromBase64(b64hash);
// }
