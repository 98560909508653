import React, { useEffect, useState, useCallback, useMemo } from 'react';
import './InternMessagesMain.scss';
import { getPhoneNumbers } from 'src/firestore';
import { useParams, useHistory } from 'react-router';
import { Layout, Menu, Input, Tabs, Tooltip } from 'antd';
import { formatPhoneNumber } from 'src/util';
import { SearchOutlined } from '@ant-design/icons';
import { InternMessageThread } from './InternMessageThread';
import InternMessageInput from './InternMessageInput';
import { ICommunicationTarget, RoleType } from '@partiful/model';

export function InternMessagesMain() {
  const { commId, service } = useParams<{ commId: string; service: string }>();
  const history = useHistory();
  const [phoneNumbers, setPhoneNumbers] = useState<ICommunicationTarget[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const selectedKeys = useMemo(() => [commId], [commId]);
  const selectedNumber = commId?.startsWith('+')
    ? commId
    : phoneNumbers.find((p) => p.id === commId)?.phoneNumber;
  useEffect(() => {
    getPhoneNumbers().then((numbers) => {
      console.log('numbers', numbers);
      setPhoneNumbers(numbers);
    });
  }, []);
  const selectPhone = useCallback(
    ({ key: id }) => {
      history.push(`/messages/${id}/${service || 'guest'}`);
    },
    [service, history]
  );
  const selectTab = useCallback(
    (tab) => {
      history.push(`/messages/${commId}/${tab}`);
    },
    [commId, history]
  );
  const searchNumbers = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  }, []);
  const shownNumbers = useMemo(() => {
    return phoneNumbers.filter(
      (n) =>
        !searchTerm ||
        n.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        n.phoneNumber.includes(searchTerm)
    );
  }, [phoneNumbers, searchTerm]);

  return (
    <Layout className="ptf-intern-messages-main">
      <Layout.Sider className="numbers-section" width={300} theme="light">
        <Input
          className="search-input"
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search numbers"
          onChange={searchNumbers}
        />
        <Menu
          className="numbers-list"
          onSelect={selectPhone}
          selectedKeys={selectedKeys}
          mode="inline"
        >
          {shownNumbers.map((phone) => (
            <Menu.Item className="number-item" key={phone.id}>
              <span>
                <span className="name">{phone.name}</span>
                <span className="tags">
                  {phone.roles?.includes(RoleType.USER) && (
                    <Tooltip overlay="Person is host">
                      <span className="tag host-tag">H</span>
                    </Tooltip>
                  )}
                  {phone.roles?.includes(RoleType.GUEST) && (
                    <Tooltip overlay="Person is guest">
                      <span className="tag guest-tag">G</span>
                    </Tooltip>
                  )}
                </span>
              </span>
              <span className="number">{formatPhoneNumber(phone.phoneNumber)}</span>
            </Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
      <Layout.Content>
        <Tabs
          className="message-thread-tabs"
          type="card"
          defaultActiveKey="guest"
          activeKey={service || 'guest'}
          onChange={selectTab}
        >
          <Tabs.TabPane tab="Guest Messages" key="guest" disabled={!commId}>
            {commId && (
              <InternMessageThread
                className="guest-message-thread"
                commId={commId}
                service="guest"
              />
            )}
            {selectedNumber && <InternMessageInput service="guest" phoneNumber={selectedNumber} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Host Messages" key="user" disabled={!commId}>
            {commId && (
              <InternMessageThread className="host-message-thread" commId={commId} service="user" />
            )}
            {selectedNumber && <InternMessageInput service="user" phoneNumber={selectedNumber} />}
          </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  );
}

export default InternMessagesMain;
