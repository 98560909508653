import './InternApp.scss';

import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import Typography from './design/Typography';
import { Layout, Spin } from 'antd';
import { InternLogout } from './login/InternLogout';
import { InternUsersMain } from './users/InternUsersMain';
import { InternMessagesMain } from './messages/InternMessagesMain';
import { InternEventsMain } from './events/InternEventsMain';
import { InternAppHeader } from './layout/InternAppHeader';
import { getConfig } from './api';
import { updateSettings } from '@partiful/firestore';
import { makePhoneNumberToCommIdConverter } from './firestore';
import { InternTemplatesMain } from './templates/InternTemplatesMain';
import { ImageSearch } from './images/ImageSearch';

export enum LoginState {
  LOADING,
  LOGGED_OUT,
  LOGGED_IN,
}

export default function InternApp() {
  const [loginState, setLoginState] = useState<LoginState>(LoginState.LOADING);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  function redirectToLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.setCustomParameters({
      hd: 'getpartiful.com',
    });
    firebase
      .auth()
      .signInWithRedirect(provider)
      .then((result) => {
        console.log('login result', result);
      })
      .catch((err) => {
        console.log('login err', err);
      });
  }
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      // Skipping this logic b/c just firebase auth mock is not working.
      // TODO: Figure out how to get jest module mocking working again.
      return;
    }
    const unsub = firebase.auth().onAuthStateChanged(
      (user) => {
        setLoginState(user != null ? LoginState.LOGGED_IN : LoginState.LOGGED_OUT);
        if (user != null) {
          if (!user.email?.endsWith('getpartiful.com')) {
            firebase.auth().signOut();
            return;
          }
          getConfig().then((config) => {
            console.log('got config', config);
            updateSettings({
              convertPhoneNumberToCommId: makePhoneNumberToCommIdConverter(
                config.phoneNumberEncodingKey
              ),
            });
            setConfigLoaded(true);
          });
          return;
        }
        if (window.location.pathname !== '/logout') {
          redirectToLogin();
        }
      },
      (error) => {
        // TODO: Handle on auth error
        console.error('auth error', error);
      }
    );
    return unsub;
  }, []);
  if (loginState === LoginState.LOADING || (loginState === LoginState.LOGGED_IN && !configLoaded)) {
    return (
      <div id="app" className="loading">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <Router>
      <Layout id="app">
        <InternAppHeader />
        <Layout.Content id="content">
          <Switch>
            <Route path="/users/:userId?">
              <InternUsersMain />
            </Route>
            <Route exact path="/events/:eventId?">
              <InternEventsMain />
            </Route>
            <Route path="/messages/:commId?/:service?">
              <InternMessagesMain />
            </Route>
            <Route path="/design/typography">
              <Typography />
            </Route>
            <Route path="/templates">
              <InternTemplatesMain />
            </Route>
            <Route path="/images">
              <ImageSearch />
            </Route>
            <Route path="/logout">
              <InternLogout />
            </Route>
            <Route exact path="/">
              <Redirect to="/events" />
            </Route>
            <Route path="*">
              <div>Not Found</div>
            </Route>
          </Switch>
        </Layout.Content>
      </Layout>
    </Router>
  );
}
