import React from 'react';
import './Typography.scss';

export default function Typography() {
  return (
    <div className="ptf-typography">
      <section>
        <h1>Heading 1: Cool stuff</h1>
        <h2>Heading 2: More cool stuff</h2>
        <h3>Heading 3: Really awesome</h3>
        <h4>Heading 4: Super good</h4>
        <h5>Heading 5: Very nice</h5>
        <h6>Heading 6: This should be small</h6>
      </section>
      <section>
        <a href="/">Link</a>
      </section>
    </div>
  );
}
