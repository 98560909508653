import './InternUsersMain.scss';

import React, { useEffect, useState } from 'react';
import { getUsers } from 'src/firestore';
import { IUser } from '@partiful/model';
import { Menu, Layout, Input, Tag, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { InternUserDetail, IMergedUser } from './InternUserDetail';
import { EMPTY_ARR, getTagColor } from 'src/util';
import { getAuthUsers, IAuthUserRecord } from 'src/api';
import { keyBy } from 'lodash';
import { useParams, useHistory } from 'react-router';

export function InternUsersMain() {
  const { userId } = useParams<{ userId: string }>();
  const [allUsers, setUsers] = useState<IMergedUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const activeUser = allUsers.find((u) => u.id === userId);
  const history = useHistory();

  useEffect(() => {
    Promise.all([getUsers(), getAuthUsers()]).then(([users, authUsers]) => {
      setUsers(mergeUsers(users, authUsers));
    });
  }, []);

  function searchUsers(e: React.FormEvent<HTMLInputElement>) {
    setSearchTerm(e.currentTarget.value);
  }

  function selectUser({ key: id }: { key: any }) {
    history.push(`/users/${id}`);
  }

  const shownUsers = allUsers.filter(
    (u) => u.name?.toLowerCase().includes(searchTerm.toLowerCase()) || u.id.includes(searchTerm)
  );

  if (allUsers.length === 0) {
    return (
      <div>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Layout className="ptf-intern-users-main">
      <Layout.Sider className="numbers-section" width={300} theme="light">
        <Input
          className="search-input"
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search users"
          onChange={searchUsers}
        />
        <Menu
          className="users-list"
          onSelect={selectUser}
          selectedKeys={activeUser?.id ? [activeUser.id] : EMPTY_ARR}
          mode="inline"
        >
          {shownUsers.map((user) => (
            <Menu.Item className="user-item" key={user.id}>
              <span className="name">{user.name || '[Unknown]'}</span>
              <span className="tags">
                {user._tags?.map((tag) => (
                  <Tag key={tag} className="user-tag" color={getTagColor(tag)}>
                    {tag}
                  </Tag>
                ))}
              </span>
            </Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
      <Layout.Content>
        {!activeUser && <a href="https://partiful.retool.com/apps/UserAdmin">Retool link</a>}
        {activeUser && <InternUserDetail user={activeUser} />}
      </Layout.Content>
    </Layout>
  );
}

function mergeUsers(users: IUser[], authUsers: IAuthUserRecord[]): IMergedUser[] {
  const recordsById = keyBy(authUsers, (u) => u.uid);
  return users.map((u) => ({ ...recordsById[u.id], ...u }));
}
