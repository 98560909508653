import './InternUserDetail.scss';

import React, { useEffect, useState } from 'react';
import { Descriptions, List, Switch, Tag } from 'antd';
import { IUser, IEvent } from '@partiful/model';
import moment from 'moment';
import { IAuthUserRecord, setUserAsAdmin } from 'src/api';
import { formatPhoneNumber, getTagColor, ActionStatus } from 'src/util';
import { getEventsForUser } from 'src/firestore';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { CopyToClipboardButton } from 'src/components';

export interface IMergedUser extends IUser, Omit<IAuthUserRecord, 'phoneNumber'> {}

export interface IInternUserDetailProps {
  user: IMergedUser;
}

function _InternUserDetail({ user }: IInternUserDetailProps) {
  const [events, setEvents] = useState<IEvent[]>([]);
  const [adminChangeStatus, setAdminChangeStatus] = useState<ActionStatus>(ActionStatus.NONE);
  const [isAdmin, setIsAdmin] = useState<boolean>(user.customClaims?.admin || false);
  useEffect(() => {
    getEventsForUser(user.id).then((events) => {
      setEvents(events);
    });
  }, [user.id]);

  function toggleAdmin() {
    setAdminChangeStatus(ActionStatus.PENDING);
    const isAdmin = user.customClaims?.admin ? false : true;
    setUserAsAdmin(user.id, isAdmin).then(() => {
      setAdminChangeStatus(ActionStatus.DONE);
      setIsAdmin(isAdmin);
    });
  }

  return (
    <div className="ptf-intern-user-detail">
      <Descriptions>
        <Descriptions.Item label="ID">
          {user.id} <CopyToClipboardButton text={user.id} />
        </Descriptions.Item>
        <Descriptions.Item label="Created">
          {moment(user.metadata.creationTime).format('l LT')}
        </Descriptions.Item>
        <Descriptions.Item label="Internal tags">
          {!user._tags?.length && <span>No tags</span>}
          {user._tags?.map((tag) => (
            <Tag key={tag} color={getTagColor(tag)}>
              {tag}
            </Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Last login">
          {moment(user.metadata.lastSignInTime).format('l LT')}
        </Descriptions.Item>
        <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
        <Descriptions.Item label="Phone">{formatPhoneNumber(user.phoneNumber)}</Descriptions.Item>
        <Descriptions.Item label="Is Admin">
          <Switch
            checked={isAdmin}
            loading={adminChangeStatus === ActionStatus.PENDING}
            onChange={toggleAdmin}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </Descriptions.Item>
      </Descriptions>
      <h4>Events ({events.length})</h4>
      <List className="user-events-list" bordered={true} split={true} size="small">
        {events.map((event) => (
          <List.Item key={event.id} className="user-event-item">
            <List.Item.Meta
              title={
                <Link to={`/events/${event.id}`}>
                  {_.startCase(event.title)} ({event.respondedGuestCount}/{event.guestCount})
                </Link>
              }
            />
            <div className="event-date">
              {moment(event.startDate).format('l LT')} | Created{' '}
              {moment(event.publishedAt).format('l LT')}
            </div>
          </List.Item>
        ))}
      </List>
    </div>
  );
}
export const InternUserDetail = React.memo(_InternUserDetail);
export default InternUserDetail;
