import React from 'react';
import { Button } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import { ButtonProps } from 'antd/lib/button';

export interface ICopyToClipBoardButtonProps extends ButtonProps {
  text: string;
}

export function CopyToClipboardButton({ text, ...props }: ICopyToClipBoardButtonProps) {
  return (
    <CopyToClipboard text={text}>
      <Button icon={<CopyOutlined />} type="link" {...props} />
    </CopyToClipboard>
  );
}
