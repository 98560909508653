import firebase from 'firebase/app';
import { ID } from '@partiful/model';

export interface IAuthUserRecord {
  uid: string;
  email: string | null;
  emailVerified: boolean;
  displayName: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  disabled: boolean;
  metadata: firebase.auth.UserMetadata;
  customClaims: null | { admin?: boolean };
  providerData: firebase.auth.AuthProvider[];
}

export async function getAuthUsers(): Promise<IAuthUserRecord[]> {
  const result = await firebase.functions().httpsCallable('getAuthUsers')();
  return result.data;
}

export async function setUserAsAdmin(id: ID<'user'>, admin: boolean): Promise<boolean> {
  const result = await firebase.functions().httpsCallable('setUserAsAdmin')({ userId: id, admin });
  return result.data;
}
