export const DIGITS = /\d+/g;
const EMPTY_ARR: any[] = [];
export const US_COUNTRY_CODE_PREFIX = '+1';

export function coercePhoneNumber<T = string | undefined>(s: T): T {
  if (s == null) {
    return s;
  }
  // TODO: Is there a better way to type this?
  return ((US_COUNTRY_CODE_PREFIX +
    (((s as any) as string).match(DIGITS) || EMPTY_ARR).join('')) as any) as T;
}

export function formatPhoneNumber(s?: string) {
  if (s == null) {
    return s;
  }
  return [s.slice(2, 5), s.slice(5, 8), s.slice(8)].join('-');
}

export function stripCountryCode<T extends string | undefined | null>(s: T): T {
  if (s == null || !s.startsWith(US_COUNTRY_CODE_PREFIX)) {
    return s;
  }
  return s.slice(2) as T;
}
