import './InternEventDetail.scss';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Descriptions, Statistic } from 'antd';
import moment from 'moment';
import { sum, values } from 'lodash';

import { IPublishedEvent, IUser, RSVP_STATUSES, IGuest, IHostMessage } from '@partiful/model';
import { cn } from '@partiful/web-util';
import { pick, EMPTY_ARR } from '@partiful/util';
import { onGuestsChanged, convertPhoneNumberToCommId, getHostMessages } from 'src/firestore';
import { InternGuestList } from '../guests';

import InternHostMessagesList from './InternHostMessagesList';
import { CopyToClipboardButton } from 'src/components';
import { formatDateForInvitation, Str } from 'src/util';

export interface IInternEventDetailProps {
  className?: string;
  event: IPublishedEvent;
  owners?: IUser[];
}

function _InternEventDetail({ className, event, owners = EMPTY_ARR }: IInternEventDetailProps) {
  const invitedCount = sum(values(event.guestStatusCounts));
  const [guests, setGuests] = useState<IGuest[]>([]);
  const [hostMessages, setHostMessages] = useState<IHostMessage[]>([]);
  const user = owners[0];
  useEffect(() => {
    getHostMessages(event.id).then((messages) => {
      console.log('got messages', messages);
      setHostMessages(messages);
    });
    // TODO: Subscribe to event changes.
    const unsub = onGuestsChanged(event.id, setGuests, (error) =>
      console.error('error getting guests', error)
    );
    return unsub;
  }, [event.id]);

  return (
    <div className={cn('ptf-intern-event-detail', className)}>
      <Descriptions className="info-section" size="small" layout="horizontal" column={1}>
        <Descriptions.Item label="ID">
          {event.id} <CopyToClipboardButton text={event.id} />
        </Descriptions.Item>
        <Descriptions.Item label="Status">{event.status}</Descriptions.Item>
        <Descriptions.Item label="User ID">
          {user?.id}
          <CopyToClipboardButton text={user?.id} />
        </Descriptions.Item>
        <Descriptions.Item label="Host Name">
          {event.hostName} |{' '}
          <Link to={`/messages/${convertPhoneNumberToCommId(user?.phoneNumber)}/user`}>
            View message history
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Created">
          {formatDateForInvitation(event.publishedAt, event.timezone)} (
          {moment(event.publishedAt).fromNow()})
        </Descriptions.Item>
        <Descriptions.Item label="Date/Time">
          {formatDateForInvitation(event.startDate, event.timezone)} (
          {moment(event.startDate).fromNow()})
        </Descriptions.Item>
        <Descriptions.Item label="Short Link">
          {event.shortUrl ? <a href={event.shortUrl}>{event.shortUrl}</a> : '[Not found]'}
        </Descriptions.Item>
        <Descriptions.Item label="Location">{event.location || '[Not set]'}</Descriptions.Item>
        <Descriptions.Item label="Description">
          {Str.quote(event.description ?? '')}
        </Descriptions.Item>
        <Descriptions.Item label="Invitation Message">
          {Str.quote(event.invitationMessage ?? '')}
        </Descriptions.Item>
        <Descriptions.Item label="Sent Host Reminders">
          {event.sentHostReminders?.join(', ')}
        </Descriptions.Item>
      </Descriptions>
      <section className="stats-section">
        <h4>Invite Summary</h4>
        <div className="stats">
          <Statistic title="Invited" value={invitedCount} />
          <Statistic
            title="Responded"
            value={sum(values(pick(event.guestStatusCounts, RSVP_STATUSES)))}
            suffix={`/ ${invitedCount}`}
          />
          <Statistic
            title="Attending"
            value={event.guestStatusCounts.GOING}
            suffix={`/ ${invitedCount}`}
          />
        </div>
      </section>
      <section className="guests-section">
        <h4>Guests:</h4>
        <InternGuestList className="guest-list" guests={guests} />
      </section>
      <section className="host-messages-section">
        <h4>Messages:</h4>
        <InternHostMessagesList hostMessages={hostMessages} guests={guests} />
      </section>
    </div>
  );
}

export const InternEventDetail = React.memo(_InternEventDetail);
export default InternEventDetail;
