import React, { useState, useEffect } from 'react';
import './ImageSearch.scss';
import { Input } from 'antd';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { uniqBy } from 'lodash';
import firebase from 'firebase/app';

const imageSearch = require('image-search-google');

function _ImageSearch() {
  // const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<ImageResult[]>([]);
  function submitSearch(search: string) {
    Promise.all([searchGoogleImages(search), searchGiphy(search)]).then(
      ([googleResults, giphyResults]) => {
        setResults(googleResults);
      }
    );
  }
  useEffect(() => {
    // /dbs/prod_v0/communities/k4YFHGhiE70eEQdlsRPe
    console.log('load community');
    firebase
      .firestore()
      .doc('/dbs/prod_v0/communities/k4YFHGhiE70eEQdlsRPe')
      .get()
      .then((result) => {
        console.log('got result', result.data());
      });
  }, []);
  return (
    <div className="ptf-image-search">
      <Input.Search onSearch={submitSearch} />
      <div className="results-container">
        {results.map((r) => (
          <div className="image-result" key={r.url}>
            <div>
              <a href={r.context}>{r.snippet}</a>
            </div>
            <img className="image" src={r.url} alt={r.snippet} />
          </div>
        ))}
      </div>
    </div>
  );
}
export const ImageSearch = React.memo(_ImageSearch);
export default ImageSearch;

interface ImageResult {
  context: string; // website url
  snippet: string; // title
  thumbnail: string; // url of the image thumbnail
  url: string; // url of the image
}

function searchGiphy(search: string) {
  const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY!);
  gf.search(search).then((result) => {
    console.log('got giphy results', result);
    return result.data;
  });
}

function searchGoogleImages(search: string) {
  console.log('search', search);
  // GET https://customsearch.googleapis.com/customsearch/v1
  const client = new imageSearch(
    process.env.REACT_APP_SEARCH_ENGINE_ID!,
    process.env.REACT_APP_IMAGE_SEARCH_ENGINE_API_KEY!
  );
  const options = { page: 1, type: 'photo', size: 'medium' };
  return client
    .search(search, options)
    .then((images: ImageResult[]) => {
      console.log('got images', images);
      return uniqBy(images, (im) => new URL(im.context).host);
      /*
		[{
			'url': item.link,
            'thumbnail':item.image.thumbnailLink,
            'snippet':item.title,
            'context': item.image.contextLink
		}]
		 */
    })
    .catch((error: any) => console.log(error));
}
