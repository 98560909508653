import firebase from 'firebase/app';

export interface ISendMessageParams {
  service: 'user' | 'guest';
  phoneNumber: string;
  message: string;
  userId?: string;
  guestId?: string;
  eventId?: string;
  type?: string;
}

export async function sendMessage(params: ISendMessageParams) {
  const result = await firebase.functions().httpsCallable('sendManualMessage')(params);
  return result.data;
}
