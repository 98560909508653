import './InternMessageInput.scss';

import React, { useState, useCallback } from 'react';
import { Input, Button } from 'antd';

import { ActionStatus, NO_OP } from '@partiful/util';

import { sendMessage } from 'src/api';

export interface IInternMessageInputProps {
  service: 'guest' | 'user';
  phoneNumber: string;
  userId?: string;
  guestId?: string;
  eventId?: string;
  onSent?(message: string): void;
  sendOnEnter?: boolean;
}
const SIZE_CONFIG = { minRows: 1, maxRows: 4 };
const SEND_CONFIRMATION_DELAY_MS = 500;

function _InternMessageInput({
  service,
  phoneNumber,
  onSent = NO_OP,
  sendOnEnter = true,
  // TODO: Need to memoize. This will generate a new object every time
  ...refs
}: IInternMessageInputProps) {
  const [value, setValue] = useState<string>('');
  const [sendStatus, setSendStatus] = useState<ActionStatus>(ActionStatus.READY);
  const changeValue = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  }, []);
  const send = useCallback(() => {
    setSendStatus(ActionStatus.PENDING);
    sendMessage({ service, phoneNumber, message: value, ...refs })
      .then((result) => {
        console.log('sent message', result);
        setSendStatus(ActionStatus.DONE);
        onSent(value);
        setTimeout(() => {
          setValue('');
          setSendStatus(ActionStatus.READY);
        }, SEND_CONFIRMATION_DELAY_MS);
      })
      .catch((e) => {
        console.log('error sending', e);
        setSendStatus(ActionStatus.ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, value, phoneNumber, onSent]);
  const pressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.shiftKey) {
        return;
      }
      if (sendOnEnter) {
        e.preventDefault();
        if (e.currentTarget.value.length > 0) {
          send();
        }
      }
    },
    [sendOnEnter, send]
  );
  return (
    <div className="ptf-intern-message-input">
      <Input.TextArea
        className="message-textarea"
        value={value}
        onChange={changeValue}
        disabled={sendStatus === ActionStatus.PENDING}
        onPressEnter={pressEnter}
        autoSize={SIZE_CONFIG}
      />
      <Button
        className="send-button"
        disabled={!value || sendStatus === ActionStatus.DONE}
        loading={sendStatus === ActionStatus.PENDING}
        onClick={send}
        type="primary"
      >
        {sendStatus === ActionStatus.PENDING
          ? 'Sending'
          : sendStatus === ActionStatus.DONE
          ? 'Sent!'
          : 'Send'}
      </Button>
    </div>
  );
}
export const InternMessageInput = React.memo(_InternMessageInput);
export default InternMessageInput;
