import './InternEventsMain.scss';

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Form,
  Breadcrumb,
  Button,
  Empty,
  Modal,
  DatePicker,
  notification,
  Tooltip,
  Select,
} from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router';
import moment from 'moment-timezone';
import _ from 'lodash';

import { IPublishedEvent, ID, IUser } from '@partiful/model';
import { deleteEvent, updateEvent } from '@partiful/firestore';
import { pluralize, EMPTY_ARR, defer } from '@partiful/util';

import { getEvents, getUsers } from 'src/firestore';
import InternEventsFilters, {
  DEFAULT_FILTER_STATE,
  IEventsFilterState,
  applyFiltersToEvents,
} from './InternEventsFilters';
import { InternEventsList } from './InternEventsList';
import { InternEventDetail } from './InternEventDetail';

const DEFAULT_SIMULATED_DATE_TIME_LIMIT = moment.duration(1, 'minutes');
const SIMULATED_DATE_TIME_PICKER_OPTIONS = {
  minuteStep: 10,
  defaultValue: moment('10:00', 'HH:mm'),
};

export function InternEventsMain() {
  const { eventId } = useParams<{ eventId: string }>();
  const history = useHistory();
  const formRef = useRef<FormInstance>(Form.useForm()[0]);
  const [sort, setSort] = useState<{ field: 'publishedAt' | 'startDate'; order: 'asc' | 'desc' }>();
  const [filterState, setFilterState] = useState<IEventsFilterState>(DEFAULT_FILTER_STATE);
  const [allEvents, setAllEvents] = useState<IPublishedEvent[]>(EMPTY_ARR);
  const [allUsers, setAllUsers] = useState<IUser[]>(EMPTY_ARR);
  const [selectedEventIds, setSelectedEventIds] = useState<ID<'event'>[]>([]);
  const shownEvents = useMemo(
    () =>
      _.orderBy(applyFiltersToEvents(allEvents, filterState, allUsers), sort?.field, sort?.order),
    [allEvents, filterState, allUsers, sort]
  );

  const activeEvent = allEvents.find((e) => e.id === eventId);

  function findUser(userId?: string) {
    return allUsers.find((u) => u.id === userId);
  }

  useEffect(() => {
    getEvents().then((events) => {
      console.log('events', events);
      setAllEvents(events as IPublishedEvent[]);
    });
    getUsers().then((users) => {
      setAllUsers(users);
    });
  }, []);

  useEffect(() => {
    if (
      eventId != null &&
      !shownEvents.some((e) => e.id === eventId) &&
      filterState !== DEFAULT_FILTER_STATE
    ) {
      // clear id
      history.push(`/events`);
    }
  }, [shownEvents, eventId, filterState, history]);

  const deleteActiveEvent = useCallback(() => {
    if (eventId == null) {
      return;
    }
    Modal.confirm({
      title: 'Confirm deletion',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure? This action cannot be undone.',
      okText: 'Delete event',
      onOk() {
        deleteEvent(eventId).then(() => {
          setAllEvents(allEvents.filter((e) => e.id !== eventId));
        });
      },
      onCancel() {},
    });
  }, [eventId, allEvents]);

  function deleteSelectedEvents() {
    Modal.confirm({
      title: 'Confirm deletion',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure? This action cannot be undone.',
      okText: `Delete ${selectedEventIds.length} ${pluralize('event', selectedEventIds.length)}`,
      onOk() {
        Promise.all(selectedEventIds.map((id) => deleteEvent(id))).then(() => {
          setSelectedEventIds([]);
          setAllEvents(allEvents.filter((e) => !selectedEventIds.includes(e.id)));
        });
      },
      onCancel() {},
    });
  }

  const setSimulatedDate = useCallback(
    (d: moment.Moment | null) => {
      const update = {
        _simulatedDate: d?.toDate() || undefined,
        _simulatedDateExpiration:
          d != null ? moment().add(DEFAULT_SIMULATED_DATE_TIME_LIMIT).toDate() : undefined,
      };
      const event = allEvents.find((e) => e.id === eventId);
      setAllEvents(allEvents.map((e) => (e.id === eventId ? { ...e, ...update } : e)));
      updateEvent(eventId, update)
        .then(() => {
          if (d != null) {
            setTimeout(() => {
              setAllEvents(
                allEvents.map((e) =>
                  e.id === eventId
                    ? { ...e, _simulatedDate: undefined, _simulatedDateExpiration: undefined }
                    : e
                )
              );
            }, DEFAULT_SIMULATED_DATE_TIME_LIMIT.asMilliseconds());
          }
          notification.success({
            message: d != null ? 'Simulated time set!' : 'Simulated time cleared!',
            description:
              d != null
                ? `Set time to ${d?.format('M/DD/YY h:mm A')} for ${event?.hostName}'s event "${
                    event?.title
                  }" for the next minute`
                : undefined,
            duration: 1,
          });
        })
        .catch((e) => {
          console.error(e);
          notification.error({ message: 'Failed to set simulated time', duration: 0 });
          setAllEvents(
            allEvents.map((e) =>
              e.id === eventId
                ? { ...e, _simulatedDate: undefined, _simulatedDateExpiration: undefined }
                : e
            )
          );
        });
    },
    [eventId, allEvents]
  );

  function changeSort(sortStr: string) {
    const [field, order] = sortStr.split('-');
    setSort({ field: field as any, order: order as any });
    console.log('set sort', field, order);
  }

  return (
    <div className="ptf-intern-events-main">
      <div className="pane filter-pane">
        <header className="pane-header">
          <div>Filters</div>
          <div className="actions">
            {!_.isEqual(filterState, DEFAULT_FILTER_STATE) && (
              <Button
                type="link"
                onClick={() => {
                  setFilterState(DEFAULT_FILTER_STATE);
                  defer(() => formRef.current.resetFields());
                }}
              >
                Reset
              </Button>
            )}
          </div>
        </header>
        <InternEventsFilters
          formName="filter-form"
          className="filter-form pane-content"
          form={formRef.current}
          users={allUsers}
          onChange={setFilterState}
          {...filterState}
        />
      </div>
      <div className="pane results-pane">
        <header className="pane-header">
          <div>
            Found {shownEvents.length} {pluralize('event', shownEvents.length)}
          </div>
          {selectedEventIds.length === 0 && (
            <Select
              className="sort-picker"
              placeholder="Sort by..."
              value={sort ? `${sort.field}-${sort.order}` : undefined}
              onChange={changeSort}
            >
              <Select.Option value="publishedAt-asc">↓ Create Time</Select.Option>
              <Select.Option value="publishedAt-desc">↑ Create Time</Select.Option>
              <Select.Option value="startDate-asc">↓ Event Date</Select.Option>
              <Select.Option value="startDate-desc">↑ Event Date</Select.Option>
            </Select>
          )}
          {selectedEventIds.length > 0 && (
            <Button.Group className="actions">
              {selectedEventIds.length < allEvents.length && (
                <Button
                  type="link"
                  onClick={() => setSelectedEventIds(allEvents.map((e) => e.id as string))}
                >
                  Select all
                </Button>
              )}
              {selectedEventIds.length > 0 && (
                <Button type="link" onClick={() => setSelectedEventIds(EMPTY_ARR)}>
                  De-select all
                </Button>
              )}
            </Button.Group>
          )}
        </header>
        <InternEventsList
          className="events-list pane-content"
          events={shownEvents}
          activeEventId={activeEvent?.id}
          selectedEventIds={selectedEventIds}
          onClick={(event) => {
            history.push(`/events/${event.id}`);
          }}
          onSelect={(_event, _checked, selectedEventIds) => setSelectedEventIds(selectedEventIds)}
        />
      </div>
      <div className="pane detail-pane">
        <header className="pane-header">
          {selectedEventIds.length > 1 ? (
            <span>
              {selectedEventIds.length} {pluralize('event', selectedEventIds.length)} selected
            </span>
          ) : (
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item>{findUser(activeEvent?.owners[0].id)?.name}</Breadcrumb.Item>
              <Breadcrumb.Item>{activeEvent?.title}</Breadcrumb.Item>
            </Breadcrumb>
          )}
          {selectedEventIds.length > 1 ? (
            <Button type="link" onClick={deleteSelectedEvents}>
              Delete all
            </Button>
          ) : (
            activeEvent && (
              <Button type="link" onClick={deleteActiveEvent}>
                Delete
              </Button>
            )
          )}
        </header>
        <div className="actions">
          {activeEvent && (
            <Tooltip overlay="Set simulated time for next minute">
              <DatePicker
                className="simulated-date-picker"
                placeholder="Set simulated time"
                picker="date"
                showTime={SIMULATED_DATE_TIME_PICKER_OPTIONS}
                format="M/DD/YY h:mm A"
                onChange={setSimulatedDate}
                value={
                  activeEvent._simulatedDate != null &&
                  moment().isBefore(activeEvent._simulatedDateExpiration)
                    ? moment(activeEvent._simulatedDate)
                    : null
                }
              />
            </Tooltip>
          )}
        </div>
        {activeEvent ? (
          <InternEventDetail
            className="pane-content"
            event={activeEvent}
            owners={_.compact(activeEvent.owners.map((o) => findUser(o.id)))}
          />
        ) : (
          <Empty
            className="pane-content"
            description="No event selected"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
}
