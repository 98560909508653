import moment from 'moment';
import { range, flatMap } from './lodash';

export const SHORT_TIMEZONE_REGEX = /^[A-Z]{2}T$/;

// startHour and endHour are 0-23 ints representing hours of the day (inclusive)
// Returns 24-hour strings like "17:15"
// Minute interval should divide evenly into 60
export function generateTimes(minuteInterval: number, startHour = 0, endHour = 23): string[] {
  if (startHour < 0 || startHour > 23 || endHour < 0 || endHour > 23) {
    throw new Error('Invalid start and end time input to generateTimes(), must be 0-23');
  }
  const hours =
    startHour > endHour
      ? range(startHour, 24).concat(range(0, endHour + 1))
      : range(startHour, endHour + 1);
  // TODO: Is there a cleaner way to do this?
  return flatMap(
    hours.map((hour) =>
      range(0, 60, minuteInterval).map(
        (minute) => `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`
      )
    )
  );
}

// Iso weekday
export enum DayOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

// Gets the next day of week from today. minimumOffset = number of days it has to be, 0 means that it could be today. dayOfWeek is 1-7 number. (Mon-Sun)
export function getNextDayOfWeek(
  dayOfWeek: DayOfWeek | DayOfWeek[],
  minimumDaysOffset = 0
): moment.Moment {
  const now = moment();
  const currentDay = now.isoWeekday();
  if (Array.isArray(dayOfWeek)) {
    return moment.min(
      ...dayOfWeek.map((day) => now.isoWeekday(Math.abs(day - currentDay) + minimumDaysOffset))
    );
  }
  return now.isoWeekday(Math.abs(dayOfWeek - currentDay) + minimumDaysOffset);
}

// timeStr must be HH:mm. no seconds supported.
export function setTimeOnDate(
  d: moment.Moment | undefined,
  timeStr: string
): moment.Moment | undefined {
  return d
    ?.hours(+timeStr.slice(0, 2))
    .minutes(+timeStr.slice(3))
    .seconds(0);
}

export function getLocalShortTimezone(extraShort = false) {
  const guessTimezone = new Date()
    .toLocaleDateString(undefined, { timeZoneName: 'short' })
    .slice(-3);
  return SHORT_TIMEZONE_REGEX.test(guessTimezone)
    ? extraShort
      ? guessTimezone[0] + guessTimezone[2]
      : guessTimezone
    : undefined;
}

// Returns IANA timezone
export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
