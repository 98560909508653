import './InternLogout.scss';
import React, { useEffect } from 'react';
import firebase from 'firebase/app';

export function InternLogout() {
  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('logged out!');
      });
  });
  return <div className="ptf-intern-logout">Logging out...</div>;
}
