import './InternMessageThread.scss';

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import Linkify from 'react-linkify';
import moment from 'moment-timezone';

import { IMessage } from '@partiful/model';
import { cn } from '@partiful/web-util';
import { ActionStatus } from '@partiful/util';

import { CONFIG } from 'src/api';
import { getLatestMessages, onMessagesUpdated, getLastMessage } from 'src/firestore';

export const DEFAULT_LIMIT = 20;

export interface IInternMessageThreadProps {
  limit?: number;
  className?: string;
  commId: string;
  service: 'guest' | 'user';
  empty?: React.ReactNode;
}

function _InternMessageThread({
  className,
  commId,
  service,
  limit = DEFAULT_LIMIT,
  empty = <Empty description="No messages" />,
}: IInternMessageThreadProps) {
  const [status, setStatus] = useState<ActionStatus>(ActionStatus.READY);
  const [latestMessages, setLatestMessages] = useState<IMessage[]>([]);
  const [newMessages, setNewMessages] = useState<IMessage[]>([]);
  const [lastMessage, setLastMessage] = useState<IMessage>();
  const containerRef = useRef<HTMLDivElement>(null);
  const messages = useMemo(() => latestMessages.concat(newMessages), [latestMessages, newMessages]);
  const channelId =
    service === 'user' ? CONFIG.userMessagingServiceSid : CONFIG.guestMessagingServiceSid;
  useEffect(() => {
    let isMounted = true;
    setStatus(ActionStatus.PENDING);
    // Fetch latest messages.
    getLatestMessages(commId, channelId, limit).then((messages) => {
      if (!isMounted) {
        return;
      }
      messages = sortBy(messages, ['date', 'messageIndex']);
      setLatestMessages(messages);
      setStatus(ActionStatus.DONE);
    });
    getLastMessage(commId, channelId).then((message) => {
      console.log('got last message', message);
      setLastMessage(message);
    });
    return () => {
      isMounted = false;
    };
  }, [commId, channelId, limit]);

  useEffect(() => {
    setNewMessages([]);
    const unsub = onMessagesUpdated(
      commId,
      channelId,
      (newMessages) => {
        console.log('Got new messages', newMessages);
        setNewMessages(newMessages);
      },
      (error) => {
        console.log('error', error);
      }
    );
    return unsub;
  }, [commId, channelId]);

  useEffect(() => {
    if (containerRef.current != null) {
      // Scroll to bottom.
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);
  // NTH: Infinite scrolling up
  return (
    <div
      className={cn('ptf-intern-message-thread', className, {
        empty: messages.length === 0,
        loading: status === ActionStatus.PENDING,
      })}
      ref={containerRef}
    >
      {messages.length === 0 && status === ActionStatus.PENDING && <Spin />}
      {messages.length === 0 && status !== ActionStatus.PENDING && empty}
      {messages.map((message) => (
        <SMSMessage key={message.id} message={message} isLast={message.id === lastMessage?.id} />
      ))}
    </div>
  );
}

export const InternMessageThread = React.memo(_InternMessageThread);
export default InternMessageThread;
const IGNORED_KEYS = [
  'body',
  'date',
  'direction',
  'messageIndex',
  'status',
  'messageCount',
  'sid',
  'phoneNumber',
];

function SMSMessage({ message, isLast }: { message: IMessage; isLast?: boolean }) {
  const sentences = message.body.split('\n');
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  useEffect(() => {
    if (message.media == null) {
      return;
    }
    Promise.all(
      message.media.map((m) =>
        m.storagePath
          ? firebase.storage().ref(m.storagePath).getDownloadURL()
          : Promise.resolve(m.url)
      )
    ).then((imageUrls) => {
      setImageUrls(imageUrls);
    });
  }, [message.media]);
  return (
    <div className={cn('message', message.direction)}>
      <div className="body">
        <Linkify>
          {sentences.map((phrase, i) => (
            <span key={i}>
              {phrase}
              {i < sentences.length - 1 && <br />}
            </span>
          ))}
        </Linkify>
      </div>
      {
        // eslint-disable-next-line jsx-a11y/alt-text
        imageUrls.map((url) => (
          <img key={url} className="image" src={url} alt="Message attachment" />
        ))
      }
      <Tooltip
        title={
          <div>
            {Object.entries(message)
              .filter(
                ([key, value]) =>
                  !IGNORED_KEYS.includes(key) &&
                  (typeof value === 'string' || typeof value === 'number')
              )
              .map(([key, value]) => (
                <div key={key}>
                  {key}: {value}
                </div>
              ))}
          </div>
        }
      >
        <div className={cn('date', { 'last-message': isLast })}>
          {moment(message.date).format('l LTS')}[{message.status}]
        </div>
      </Tooltip>
    </div>
  );
}
