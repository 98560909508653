import firebase from 'firebase/app';

let GUEST_REMINDERS: { id: string; name: string }[];

export async function getReminders(): Promise<{ id: string; name: string }[]> {
  if (GUEST_REMINDERS != null) {
    return GUEST_REMINDERS;
  }
  const result = await firebase.functions().httpsCallable('getReminders')();
  GUEST_REMINDERS = result.data;
  return result.data;
}

export interface ISendReminderParams {
  reminderId: string;
  eventId: string;
  guestId: string;
}

export async function sendReminder(params: ISendReminderParams) {
  const result = await firebase.functions().httpsCallable('forceSendGuestReminder')(params);
  return result.data;
}
