import './InternTemplatesMain.scss';

import React, { useEffect, useState, useMemo } from 'react';
import { Spin, Card, Cascader, Divider, Select } from 'antd';
import type { CascaderOptionType } from 'antd/lib/cascader';
import _ from 'lodash';

import { cn } from '@partiful/web-util';
import { IPublishedEvent, GuestStatus, IGuest } from '@partiful/model';
import { getGuests } from '@partiful/firestore';

import { getEvents } from 'src/firestore';
import { getTemplates } from '../api';
import { getTemplateFunc } from './templateUtil';

export function InternTemplatesMain() {
  const [templates, setTemplates] = useState<
    { id: string; template: string; templateFunc: (...args: any[]) => string[] }[]
  >([]);
  const [events, setEvents] = useState<IPublishedEvent[]>([]);
  const [guests, setGuests] = useState<IGuest[]>([]);
  const [previewEvent, setPreviewEvent] = useState<IPublishedEvent>();
  const [previewGuest, setPreviewGuest] = useState<IGuest>();
  const eventCascaderOptions: CascaderOptionType[] = useMemo(
    () =>
      _(events)
        .groupBy((e) => e.owners[0].id)
        .map((events, id) => ({
          value: id,
          label: events[0].hostName,
          children: events.map((e) => ({ label: e.title, value: e.id })),
        }))
        .value(),
    [events]
  );
  useEffect(() => {
    getTemplates().then((templates) => {
      setTemplates(
        templates.map((t) => ({ ...t, templateFunc: getTemplateFunc(t.id, t.template) }))
      );
    });
    getEvents().then(setEvents);
  }, []);

  function chooseEvent([_userId, eventId]: (string | number)[]) {
    const event = events.find((e) => e.id === eventId);
    setPreviewEvent(event);
    if (event != null) {
      getGuests(event.id).then(setGuests);
    }
  }
  function chooseGuest(guestId: string) {
    setPreviewGuest(guests.find((g) => g.id === guestId));
  }

  return (
    <div className="ptf-intern-templates-main">
      <div className="preview-picker-container">
        <Cascader
          className="event-picker"
          placeholder="Select event to preview"
          options={eventCascaderOptions}
          onChange={chooseEvent}
        />
        {guests.length > 0 && (
          <Select placeholder="Select guest" allowClear={true} onChange={chooseGuest}>
            {guests.map((g) => (
              <Select.Option key={g.id} value={g.id}>
                {g.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
      <div className={cn('templates-grid', { empty: templates.length === 0 })}>
        {templates.length === 0 && <Spin />}
        {templates.map((t) => (
          <Card
            key={t.id}
            size="small"
            title={t.id.split(':').map(_.startCase).join(':')}
            className="template-card"
            bordered={true}
          >
            {makeMessageContent(
              previewEvent
                ? t.templateFunc(
                    previewEvent,
                    previewGuest ?? { name: '{Guest}', status: GuestStatus.GOING },
                    {
                      guestCountsDiff: {
                        going: 2,
                        goingNames: 'Jane, George',
                        maybe: 1,
                        maybeNames: 'Beth',
                        declined: 0,
                      },
                    }
                  )
                : t.template.split('\r')
            )}
          </Card>
        ))}
      </div>
    </div>
  );
}

function makeMessageContent(input: string[]) {
  return input.map((m, i) => (
    <div key={i} className="section">
      {m.split('\n').map((p, ii) => (
        <p key={ii}>{p}</p>
      ))}
      <Divider />
    </div>
  ));
}
