import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import InternApp from './InternApp';
import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import { initalize } from '@partiful/firestore';
import baseConfig from './baseFirebaseConfig.json';

firebase.initializeApp({
  ...baseConfig,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
});
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

if (process.env.REACT_APP_FIRESTORE_DB != null) {
  initalize(firebase, { rootDb: process.env.REACT_APP_FIRESTORE_DB });
} else {
  throw new Error('Firestore DB not set');
}

if (process.env.NODE_ENV === 'production') {
  firebase.setLogLevel('silent');
} else {
  if (process.env.REACT_APP_FUNCTIONS_EMULATOR_URL != null) {
    firebase.functions().useFunctionsEmulator(process.env.REACT_APP_FUNCTIONS_EMULATOR_URL);
  }
  if (process.env.REACT_APP_FIRESTORE_URL != null) {
    firebase.firestore().settings({
      host: process.env.REACT_APP_FIRESTORE_URL,
      ssl: false,
    });
  }
}

ReactDOM.render(<InternApp />, document.getElementById('root'));
