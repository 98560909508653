import firebase from 'firebase/app';

export interface IConfig {
  phoneNumberEncodingKey: string;
  guestMessagingServiceSid: string;
  userMessagingServiceSid: string;
}
const STORAGE_KEY = 'partifulInternConfig';
export var CONFIG: IConfig;

export async function getConfig(): Promise<IConfig> {
  // Check localstorage.
  const storedConfig = window.localStorage.getItem(STORAGE_KEY);
  if (storedConfig != null) {
    console.info('Loaded config from localStoarge', storedConfig);
    const config = JSON.parse(storedConfig);
    CONFIG = config;
    return config;
  }
  const result = await firebase.functions().httpsCallable('getConfig')();
  CONFIG = result.data;
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(result.data));
  return result.data;
}
